.app, .ant-layout {
    font-family: 'Roboto', sans-serif;
}

a {
    color: #bd576f !important;
}

/*******BRAND*******/

.brand-content {
    padding: 30px 0;
}

.brand-content img {
    width: 100%;
}

.brand-content h2 {
    color: #bd576f;
    text-transform: uppercase;
    font-size: 2em;
}

/*******HEADER*******/

.header {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    background: linear-gradient(to right, #bd576f, #f0a373);
}

.header img {
    width: 100%;
}

.header h1 {
    color: #fff;
    font-size: 2.2em;
    letter-spacing: 0.1em;
    font-weight: bold;
}

.header p {
    color: #fff;
    font-size: 1.1em;
    font-weight: 300;
}

/*******FORMULAIRE*******/
.item-content {
    padding: 20px 0;
    border-radius: 5px;
}

.item-description{
    margin: -15px 0 10px 40px;
}

.ant-divider-inner-text {
    color: #bd576f;
    font-weight: 500;
    font-size: 1.4em;
    padding: 0 !important;
    white-space: break-spaces !important;
}

.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
    width: 2% !important;
}

.ant-radio-group {
    width: 100%;
}

.label-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.ant-radio-button-wrapper {
    border-left-width: thin !important;
    margin: 5px 0 !important;
}

.ant-radio-button-wrapper-checked, .ant-radio-button-checked, .ant-radio-button-wrapper:hover, .ant-radio-button-wrapper:focus-within, .ant-radio-button-wrapper:focus-visible, .ant-btn:hover, .ant-btn:focus {
    border-color: #f0a373 !important;
    background: linear-gradient(to right, #bd576f, #f0a373);
    color: #fff !important;
    box-shadow: 0px 0px 5px #bd576f;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #bd576f !important;
}

.btn-content {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.btn-content .btn-submit {
    background-color: #bd576f;
    padding: 5px 20px;
    width: 1000%;
    color: #fff;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
    cursor: pointer;
}

/*******MODAL*******/
.ant-modal-content {
    border-radius: 10px !important;
}

.ant-modal-content img{
    max-width: 300px;
    margin-bottom: 50px;
}

/*******CARDS*******/

.item-card {
    opacity: 0.1;
}

.active {
    opacity: 1;
    border-color: #bd576f;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
}

.ant-card{
    margin: 0 30px;
}

.card-content {
    display: flex;
    justify-content: center;
}

.ant-card-meta {
    justify-content: center;
}

.ant-btn-primary {
    background-color: #bd576f !important;
    border-color: #bd576f !important;
}